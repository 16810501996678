




















































import Project from "@/interfaces/Project";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "AboutStudent",
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
});
