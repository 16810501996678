
































import { ActiveProject } from "@/interfaces/Project";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "ProjectCardContactInformation",
  props: {
    project: {
      type: Object as PropType<ActiveProject>,
      required: true,
    },
  },
});
