
































































































































import { getActiveProjects } from "@/api/projectService";
import ProjectCardSeller from "@/components/seller/my-students/ProjectCardSeller.vue";
import StripeRequirements from "@/components/profile/StripeRequirements.vue";
import { isAvailable } from "@/helpers/availabilityUtils";
import useAsyncData from "@/hooks/useAsyncData";
import { useRouter } from "@/router";
import { userModule } from "@/store/modules/user";
import { computed, defineComponent, provide } from "@vue/composition-api";

export default defineComponent({
  name: "MyStudents",
  components: { StripeRequirements, ProjectCardSeller },
  setup() {
    const { navigate } = useRouter();
    const { isLoading, data: projects, refresh } = useAsyncData(() =>
      getActiveProjects("seller", userModule.state.userId)
    );

    provide("getProjects", refresh);

    const available = computed(() => isAvailable(userModule.state.profile) && userModule.state.profile.newStudentCapacity);

    return {
      available,
      isLoading,
      navigate,
      projects,
    };
  },
});
