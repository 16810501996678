





























































































































































































import { computed, defineComponent, inject, PropType, provide, ref } from "@vue/composition-api";
import { ActiveProject } from "@/interfaces/Project";
import { getMembership } from "@/api/membershipService";
import { getStudentID } from "@/helpers/projectUtils";
import Moment from "@/services/moment";
import DynamicSalary from "@/components/seller/my-students/DynamicSalary.vue";
import MembershipInfoSeller from "@/components/membership/MembershipInfoSeller.vue";
import ProjectCardAboutProject from "@/components/seller/my-students/ProjectCardAboutProject.vue";
import ProjectCardContactInformation from "@/components/seller/my-students/ProjectCardContactInformation.vue";
import ReadableCategories from "@/components/shared/ReadableCategories.vue";
import StartOnlineSessionButton from "@/components/StartOnlineSessionButton.vue";
import useAsyncData from "@/hooks/useAsyncData";
import { saveStudentName } from "@/api/projectService";
import { useNotifier } from "@/providers/notifier";
import CompleteProject from "@/components/seller/my-students/CompleteProject.vue";

export default defineComponent({
  name: "ProjectCardSeller",
  components: {
    CompleteProject,
    DynamicSalary,
    MembershipInfoSeller,
    ProjectCardAboutProject,
    ProjectCardContactInformation,
    ReadableCategories,
    StartOnlineSessionButton,
  },
  props: {
    project: {
      type: Object as PropType<ActiveProject>,
      required: true,
    },
  },
  setup(props) {
    const notify = useNotifier();
    const { isLoading, data: membership, refresh } = useAsyncData(() =>
      getMembership(props.project.customerUID)
    );

    const activeMembership = computed(() => membership.value?.product !== null);
    const nameChangeLoading = ref(false);
    const planID = computed(() => membership.value?.subscription?.plan.id);
    const planName = computed(() => membership.value?.subscription?.plan.nickname);
    const studentID = getStudentID(props.project.customerUID);
    const studentName = ref("");
    const membershipEndTime = computed(() => {
      if (membership.value?.membershipEndTime) {
        return Moment.unix(membership.value.membershipEndTime).format("dddd Do MMMM");
      }
      return false;
    });

    provide("currentPlanID", planID);
    provide("currentPlanName", planName);
    provide("getMembership", refresh);

    const getProjects = inject("getProjects") as () => Promise<void>;

    async function saveName() {
      nameChangeLoading.value = true;
      try {
        await saveStudentName(props.project.ID, studentName.value);
        await getProjects();
      }
      catch (e) {
        notify({
          title: "Klarte ikke oppdatere navn 🤯",
          message: "Kontakt support hvis problemet vedvarer.",
          type: "error",
        });
      }
      nameChangeLoading.value = false;
    }

    return {
      activeMembership,
      membershipEndTime,
      isLoading,
      membership,
      nameChangeLoading,
      planName,
      saveName,
      studentID,
      studentName,
    };
  },
});
