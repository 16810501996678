
















































import { computed, defineComponent, PropType, reactive } from "@vue/composition-api";
import Membership from "@/interfaces/Membership";
import Moment from "@/services/moment";
import ChangeMembershipDialog from "@/components/membership/ChangeMembershipDialog/ChangeMembershipDialog.vue";
import { ActiveProject } from "@/interfaces/Project";
import { toNOLocale } from "@/helpers/viewUtils";

export default defineComponent({
  name: "MembershipInfoSeller",
  components: { ChangeMembershipDialog },
  props: {
    membership: {
      type: Object as PropType<Membership>,
      required: true,
    },
    project: {
      type: Object as PropType<ActiveProject>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      changeMembershipDialogVisible: false
    });

    const periodStart = props.membership.subscription?.current_period_start
      ? Moment.unix(props.membership.subscription?.current_period_start).format("Do MMM")
      : "";

    const periodEnd = props.membership.periodEnd
      ? Moment.unix(props.membership.periodEnd).format("Do MMM")
      : "";

    const endPrice = computed(() => {
      return (props.membership.subscription?.plan.amount || 0) / 100;
    });

    const productName = props.membership.product?.name;

    const weeklyLessons = computed(() => {
      return toNOLocale(props.membership.hours / 4);
    });

    return {
      endPrice,
      periodEnd,
      periodStart,
      productName,
      vm,
      weeklyLessons
    };
  },
});
