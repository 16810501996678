<template>
  <v-container>
    <v-row
      v-for="item in sellerConnectedAccountRequirements.data.value"
      :key="item.title"
    >
      <v-alert
        style="max-width: 620px; margin-bottom: 10px"
        type="error"
        shaped
      >
        <strong>{{ item.title }}</strong>
        <p>
          {{ item.message }}
        </p>
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import { userModule } from "@/store/modules/user";
import { getConnectedAccount } from "@/api/stripeService";
import { defineComponent } from "@vue/composition-api";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "StripeRequirements",
  setup() {
    const sellerConnectedAccountRequirements = useAsyncData(() => getConnectedAccount(userModule.state.userId));
    return {
      sellerConnectedAccountRequirements,
    };
  },
});
</script>
