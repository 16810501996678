import learnlinkAPI from "./learnlinkAPI";
import Membership from "@/interfaces/Membership";
import Plan from "@/interfaces/Plan";
import Product from "@/interfaces/Product";
import Receipt from "@/interfaces/Receipt";

const ENDPOINT = "/app/membership";

export const listReceipts = async(userId: string): Promise<Receipt[]> => {
  const receiptsRes = await learnlinkAPI.axios.get<Receipt[]>(
    `${ENDPOINT}/receipts/${userId}`,
  );
  return receiptsRes.data;
};

export const getMembership = async(userId: string): Promise<Membership> => {
  const membershipRes = await learnlinkAPI.axios.get<Membership>(
    `${ENDPOINT}/${userId}`,
  );
  return membershipRes.data;
};

export const getProduct = async(productID: string): Promise<Product> => {
  const productRes = await learnlinkAPI.axios.get<Product>(
    `${ENDPOINT}/product/${productID}`,
  );
  return productRes.data;
};

export const cancelMembership = async(userId: string): Promise<Membership> => {
  const membershipRes = await learnlinkAPI.axios.delete<Membership>(
    `${ENDPOINT}/${userId}`,
  );
  return membershipRes.data;
};

export const pauseMembership = async(userId: string): Promise<Membership> => {
  const membershipRes = await learnlinkAPI.axios.post<Membership>(
    `${ENDPOINT}/pause/${userId}`,
  );
  return membershipRes.data;
};

export const listPlans = async(productID: string): Promise<Plan[]> => {
  const plansRes = await learnlinkAPI.axios.get<Plan[]>(
    `${ENDPOINT}/plans/${productID}`
  );
  return plansRes.data.sort((a, b) => (a.amount - b.amount));
};

export const listProducts = async(): Promise<Product[]> => {
  const productsRes = await learnlinkAPI.axios.get<Product[]>(
    `${ENDPOINT}/products`
  );
  return productsRes.data;
};

export const updateMembership = async(customerUID: string, plan: string): Promise<Membership> => {
  const membershipRes = await learnlinkAPI.axios.put<Membership>(
    `${ENDPOINT}/${customerUID}/plan/${plan}`
  );
  return membershipRes.data;
};

export const createMembership = async(customerUID: string, plan: string): Promise<Membership> => {
  const membershipRes = await learnlinkAPI.axios.post<Membership>(
    `${ENDPOINT}/${customerUID}/${plan}`
  );
  return membershipRes.data;
};
