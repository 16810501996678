





























































































import { completeProjectBySeller } from "@/api/projectService";
import { ActiveProject } from "@/interfaces/Project";
import { useNotifier } from "@/providers/notifier";
import { computed, defineComponent, inject, PropType, reactive } from "@vue/composition-api";
import WeekdayPicker from "@/components/WeekdayPicker.vue";
import ReadableCategories from "@/components/shared/ReadableCategories.vue";

const terminationReasons = [
  {
    value: "studies",
    text: "Videre studier"
  },
  {
    value: "newJob",
    text: "Ny jobb"
  },
  {
    value: "noCapacity",
    text: "Har ikke kapasitet"
  }
];

export default defineComponent({
  components: { ReadableCategories, WeekdayPicker },
  name: "CompleteProject",
  props: {
    project: {
      type: Object as PropType<ActiveProject>,
      required: true,
    },
  },
  setup(props) {
    const notify = useNotifier();
    const vm = reactive({
      accomplishments: "",
      improvementAreas: "",
      loading: false,
      studentContinuing: null as unknown as boolean,
      terminationReason: "",
      weekdays: []
    });

    const formValid = computed(() => {
      return (vm.accomplishments !== "" &&
        vm.improvementAreas !== "" &&
        vm.studentContinuing !== null &&
        vm.terminationReason !== "" &&
        vm.weekdays.length > 0);
    });

    const getProjects = inject("getProjects") as () => Promise<void>;

    async function submit() {
      vm.loading = true;

      try {
        await completeProjectBySeller(
          props.project.ID,
          vm.accomplishments,
          vm.improvementAreas,
          vm.studentContinuing,
          vm.terminationReason,
          vm.weekdays
        );

        notify({
          title: "Oppsigelse sendt! 🙏",
          message: "Lykke til med den siste perioden som PL for denne eleven.",
          type: "success",
        });

        if (getProjects) await getProjects();
      }
      catch (e) {
        notify({
          title: "Klarte ikke si opp oppdraget 🤯",
          message: "Kontakt support hvis problemet vedvarer.",
          type: "error",
        });
      }

      vm.loading = false;
    }

    return {
      formValid,
      submit,
      terminationReasons,
      vm
    };
  }
});
