





























import { listPlans } from "@/api/membershipService";
import useAsyncData from "@/hooks/useAsyncData";
import Product from "@/interfaces/Product";
import { computed, defineComponent, inject, PropType, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "SelectPlan",
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      isLoading,
      data: plans,
    } = useAsyncData(() =>
      listPlans(props.product.id),
    );

    const vm = reactive({
      planIndex: 0,
    });

    const selectablePlans = computed(() => {
      return plans.value?.map((plan) => {
        return {
          amount: plan.amount / 100,
          id: plan.id,
          nickname: plan.nickname.split(":")[1].trim(),
        };
      });
    });

    const currentPlanID = inject("currentPlanID") as string;
    const tickLabels = computed(() => plans.value?.map(plan => {
      return +plan.id.split("-")[0] / 4;
    }));
    const plan = computed(() => {
      return selectablePlans.value
        ? selectablePlans.value[vm.planIndex]
        : {
            amount: 0,
            id: currentPlanID,
            nickname: "0 timer i uken: fant ingen medlemskap",
          };
    });

    function choosePlan(planID: string) {
      emit("choosePlan", planID);
    }

    return {
      choosePlan,
      currentPlanID,
      isLoading,
      plan,
      selectablePlans,
      tickLabels,
      vm,
    };
  },
});
