export default function makeRows<T>(items: T[], perRow: number): T[][] {
  const rows = [];
  let row: T[] = [];
  for (const [rowIndex, item] of items.entries()) {
    if (rowIndex % perRow === 0) {
      row = [];
      rows.push(row);
    }
    row.push(item);
  }
  return rows;
}

export function toNOLocale(number: number): string {
  return String(Math.round(number * 100) / 100).replace(".", ",");
}
